// This is a more generic version of the blog-grid.js script.

const $ = window.Bliss,
  $$ = $.$

document.addEventListener("DOMContentLoaded", () => {
  function resizeGridItem(item) {
    const grid = $("[data-masonry-container]")

    const rowHeight = Number.parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-auto-rows"),
      10,
    )
    const rowGap = Number.parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-row-gap"),
      10,
    )
    const rowSpan = Math.ceil(
      (item.querySelector("[data-masonry-content]").getBoundingClientRect()
        .height +
        rowGap) /
        (rowHeight + rowGap),
    )
    item.style.gridRowEnd = `span ${rowSpan}`
  }

  function resizeAllGridItems(items) {
    items.forEach((item) => {
      resizeGridItem(item)
    })
  }

  const items = $$("[data-masonry-item]")

  if (items.length > 0) {
    window.addEventListener("load", () => resizeAllGridItems(items))
    window.addEventListener("resize", () => resizeAllGridItems(items))
  }
})
