/* eslint-disable */
/* global $, $$ */

window.addEventListener("load", () => {
  const $ = window.Bliss,
    $$ = $.$

  const galleryImg = $$("[data-gallery-img]")
  const galleryImgFull = $$("[data-gallery-img-full]")
  const galleryModal = $(".modal-gallery")
  const galleryModalOverlay = $(".modal-gallery__overlay")
  const closeCursor = $("[data-close-cursor]")
  let slideIndex = 1
  const prev = $(".prev-slider")
  const next = $(".next-slider")
  const thumbsContainer = $(".gallery-cursor")

  if (galleryModal) {
    galleryImg.forEach((e) => {
      e.addEventListener("click", () => {
        const index = galleryImg.indexOf(e)
        currentSlide(index + 1)
        galleryModal.classList.add("open")
        $(".main-content").classList.add("open")
      })
    })

    closeCursor.addEventListener("click", () => {
      galleryModal.classList.remove("open")
      $(".main-content").classList.remove("open")
    })

    galleryModalOverlay.addEventListener("click", () => {
      galleryModal.classList.remove("open")
      $(".main-content").classList.remove("open")
    })

    prev.addEventListener("click", () => {
      plusSlides(-1)
    })
    next.addEventListener("click", () => {
      plusSlides(1)
    })
  }

  function plusSlides(n) {
    showSlides((slideIndex += n))
  }

  function currentSlide(n) {
    showSlides((slideIndex = n))
  }

  function createThumbs() {
    if (thumbsContainer) {
      if (galleryImg.length) {
        galleryImg.forEach(() => {
          thumbsContainer.appendChild(
            $.create({
              tag: "a",
              href: "",
              class: "dot",
              "data-cursor": "",
            }),
          )
        })
      }
    }
  }

  createThumbs()

  const dataCursor = $$("[data-cursor]")
  dataCursor.forEach((e) => {
    const index = dataCursor.indexOf(e)
    e.addEventListener("click", (x) => {
      currentSlide(index + 1)
      x.preventDefault()
    })
  })

  function showSlides(n) {
    let i,
      slides = $$(".gallery-slides"),
      captionText = document.getElementById("caption"),
      captionTitle = document.getElementById("caption-title")
    if (n > slides.length) {
      slideIndex = 1
    }
    if (n < 1) {
      slideIndex = slides.length
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none"
    }
    for (i = 0; i < dataCursor.length; i++) {
      dataCursor[i].className = dataCursor[i].className.replace(" active", "")
    }
    slides[slideIndex - 1].style.display = "block"
    dataCursor[slideIndex - 1].className += " active"
    if (captionTitle) {
      captionTitle.innerHTML = galleryImgFull[slideIndex - 1].title
    }
    if (captionText) {
      captionText.innerHTML = galleryImgFull[slideIndex - 1].alt
    }
  }
  if (galleryModal) {
    showSlides(slideIndex)
  }

  const thumbsContainerAnchors = $$(".dot")

  if (thumbsContainerAnchors.length > 20) {
    thumbsContainer.remove()
  }
})
