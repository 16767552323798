import "./main.scss"
import _throttle from "lodash/throttle"
import "blissfuljs"
import "js/lightbox"

const $ = window.Bliss,
  $$ = $.$

import { Gallery } from "js/gallery"
import { ToggleElements } from "./js/toggleElements"

import "js/blog-grid"
import "js/masonry-grid"
import "js/photo-gallery"

import { Accordion } from "./js/accordion"
import ScrollMenu from "./js/scrollmenu"

//import scrollmenu from './js/scrollmenu'

class AjaxForm {
  constructor(form) {
    this.form = form
    this.gaEventCategory = this.form.dataset.eventCategory
    this.slug = this.form.parentNode.getAttribute("id")
    this.form.addEventListener("submit", this.handleSubmit)
  }

  replaceForm = (innerHTML) => {
    const parentNode = this.form.parentNode
    const newNode = document.createElement("div")
    newNode.innerHTML = innerHTML
    parentNode.replaceChild(newNode, this.form)
    history.replaceState({}, "test", `#${this.slug}`)
    return parentNode
  }

  handleSubmit = (evt) => {
    evt.preventDefault()

    const data = new FormData(this.form)
    fetch(this.form.action, {
      method: "POST",
      credentials: "same-origin",
      body: data,
    })
      .then((response) => response.text())
      .then((text) => {
        const parentNode = this.replaceForm(text)

        const forms = $$("form", parentNode)
        forms.forEach((form) => new AjaxForm(form))

        // TODO analytics
        // if (forms.length === 0 && typeof ga !== 'undefined') {
        //   ga('gtm1.send', 'event', this.gaEventCategory, 'submit')
        // }
      })
      .catch((error) => {
        this.replaceForm(
          "<p>Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite erneut und versuchen Sie es später noch einmal.</p>",
        )
        window.console.error("Error:", error)
      })
  }
}

$.ready().then(() => {
  // let lightbox = window.lightbox

  $$("#contact-form form").forEach((form) => new AjaxForm(form))

  if ($(".memberlist")) {
    new ToggleElements()
  }

  const $tableHead = $$(".memberlist-content")
  if ($tableHead) {
    $tableHead.forEach((e) => {
      const elements = e.querySelectorAll("tr").length
      e.parentNode.querySelector(".memberlist-number").innerHTML = elements
    })
  }

  const downloadSelector = $(".blogpage__download--selector")
  const downloadMobile = $(".blogpage__download-mobile")
  if (downloadSelector) {
    downloadSelector.addEventListener("click", (e) => {
      e.preventDefault()
      downloadMobile.classList.toggle("active")
    })
  }

  const dataRestricted = $$("[data-restricted]")

  dataRestricted.forEach((e) => {
    e.addEventListener("click", (x) => {
      x.preventDefault()
    })
  })

  // Image Loader

  // Blog Grid

  /*  let container = document.querySelector('.grid-gallery')
   if (container) {
   new Masonry(container, {
   columnWidth: '.grid-sizer',
   itemSelector: '.grid-gallery-item',
   horizontalOrder: true,
   })
   }*/

  const foldoutTrigger = $$(".nl-foldout-trigger input")
  const foldoutNl = $(".nl-foldout")
  if (foldoutTrigger) {
    foldoutTrigger.forEach((e) => {
      e.addEventListener("click", () => {
        if (!foldoutNl.classList.contains("is-visible")) {
          foldoutNl.classList.add("is-visible")
        }
      })
    })
  }

  $$("[data-accordion-id]").forEach((el) => new Accordion(el))

  // $$('form').forEach(form => new AjaxForm(form))

  /*
   *  Scroll Menu
   */
  // new scrollmenu(['.navigation a', '[data-scroll-anchor]'])

  /*
   *  Mobile Nav Toggle
   */
  $("[data-toggle-mobile-nav]").addEventListener("click", (e) => {
    e.preventDefault()
    document.body.classList.toggle("nav-is-open")
  })

  window.addEventListener("resize", () => {
    document.body.classList.remove("nav-is-open")
  })

  const navPlus = $$("[data-toggle-mobile-nav-sub]")
  navPlus.forEach((element) => {
    element.addEventListener("click", () => {
      const nextSubmenu = element.parentNode.querySelector(
        ".navigation__submenu",
      )
      const nextParent =
        element.parentNode.parentNode.querySelector(".navigation__item")
      // let nextClosestIcon = element.parentNode.querySelector('[data-toggle-mobile-nav-sub]')
      //nextParent.closest('[data-toggle-mobile-nav-sub]')
      nextParent.classList.toggle("is--open")
      nextSubmenu.classList.toggle("is--open")
      // nextClosestIcon.classList.toggle('rotate')

      element.classList.toggle("rotate")
    })
  })
  /*
   * Close navigation after a click on a link
   */
  const navigation = $(".navigation")
  $.delegate(navigation, "click", ".navigation__link", () => {
    document.body.classList.remove("nav-is-open")
  })

  /*
   * Gallery
   * =======
   */
  const sliders = $$(".gallery-slide:not(.gallery--none)")
  sliders.forEach((el) => {
    new Gallery(el, {
      interval: 5000,
      autoPlay: true,
      createThumbs: true,
      endless: false,
    })
  })

  // Sidenav

  /*  let sidenavLink = $$('[data-sidenav-has-children]');
   let sidenavToggle = $$('[data-sidenav-toggle]');
   if (sidenavToggle) {
   sidenavLink.forEach(e => {
   e.addEventListener('click', c => {
   c.target.parentNode.parentNode.parentNode.querySelector('.sidenav-children').classList.toggle('active')
   })
   })
   }*/

  /*  const galleries = $$('[data-gallery-slide]')
   galleries.forEach(el => {
   new GallerySlider(el, {
   interval: 5000,
   autoPlay: true,
   createThumbs: true,
   endless: false,
   })
   })*/

  /*
   *   Set scroll indicator
   */

  // prix Litra Form

  const input = $$(".form-fieldset > input")

  if (input) {
    window.addEventListener("change", () => {
      input.forEach((e) => {
        if (!e.value == "") {
          e.classList.add("filled")
        } else {
          e.classList.remove("filled")
        }
      })
    })

    input.forEach((e) => {
      e.setAttribute("tabindex", 0)
      if (!e.value == "") {
        e.classList.add("filled")
      } else {
        e.classList.remove("filled")
      }
    })
  }

  const scrolledThreshold = 20

  window.addEventListener(
    "scroll",
    _throttle(() => {
      if (window.pageYOffset > scrolledThreshold) {
        document.body.classList.add("scrolled")
      } else {
        document.body.classList.remove("scrolled")
      }
    }, 100),
  )
})

window.addEventListener("load", () => {
  // Anchors
  const scrollItem = $(".anchors")
  if (scrollItem) {
    new ScrollMenu([".anchors a"])
  }
})
