document.addEventListener("DOMContentLoaded", () => {
  function resizeGridItem(item) {
    const grid = document.getElementsByClassName("blog-grid")[0]
    const rowHeight = Number.parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-auto-rows"),
      10,
    )
    const rowGap = Number.parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-row-gap"),
      10,
    )
    const rowSpan = Math.ceil(
      (item.querySelector(".blog-grid__item").getBoundingClientRect().height +
        rowGap) /
        (rowHeight + rowGap),
    )
    item.style.gridRowEnd = `span ${rowSpan}`
  }

  function resizeAllGridItems() {
    const allItems = document.getElementsByClassName("blog-grid__selector")
    for (let x = 0; x < allItems.length; x++) {
      resizeGridItem(allItems[x])
    }
  }

  window.addEventListener("load", resizeAllGridItems)
  window.addEventListener("resize", resizeAllGridItems)
})
